$color-light: #fff;

.light-sidebar {
  &:not(.sidebar-mini) {
    .sidebar-style-2 .sidebar-menu>li.active {
      ul.dropdown-menu li a {
        background-color: $color-light !important;
      }
    }
  }

  &.sidebar-mini .main-sidebar {
    .sidebar-menu {
      background-color: $color-light;

      li {
        &.active {
          a {
            box-shadow: none;
            color: #6777ef;
          }
        }

        ul.dropdown-menu li {
          a {
            background-color: $color-light;
          }

          &:hover a {
            background-color: $color-light;
          }
        }
      }
    }

    .sidebar-user {
      background-color: $color-light;
    }

    .dropdown-title {
      background-color: $color-light;
    }

    &:after {
      background-color: $color-light;
    }
  }

  .navbar {
    &.active {
      background-color: #fff;
    }

    .form-inline {
      .form-control {
        background-color: #f2f2f2;
      }

      .btn {
        background-color: #f2f2f2;
      }
    }
  }

  .main-sidebar .sidebar-menu li {
    &.active a {
      background-color: #f0f3ff;
    }

    ul.dropdown-menu li {
      &.active>a {
        color: #454546;
        font-weight: bold;

        &::before {
          color: #454546;
        }
      }

      a:hover {
        color:#2E67C7;

        &::before {
          color: #454546;
        }
      }
    }
  }
}