body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Toastify__toast--success {
  background: #00AA4E !important; }
.Toastify__toast--warning {
  background: #f1c40f; }
.Toastify__toast--error {
  background: #d62828 !important; }
table thead th{
  white-space: nowrap;
}



/* Shared */
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #FFF;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}
 
 
/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background-color: #00AA4E !important;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
    font-size: 16px !important;
    color: #fff;
    padding: 2px !important;
    border: 0 !important;
    line-height: 34px !important;
    padding-right: 10px !important;
    margin-top: 10px;
}
.google-icon-wrapper {
  margin-top: 1px;
  margin-left: 3px;
  border-radius: 2px;
  float: left;
  background-color: #fff;
  padding: 6px;
  margin-right: 10px;
  color: #00AA4E;
}

.text-link{
  color:#007bff!important
}  

.invoSchedule-table thead, .invoSchedule-table tbody tr{
  display: table;
    width: 100%;
    table-layout: fixed;
}

.invoSchedule-table tbody{
  display: block;
    height: 135px;
    overflow: auto;
    width: 100%;
}
  
