$hr-on-light: #CBCBCB;
$hr-on-dark: #9FA4AA;
$line-info: #498DFF; 
$line-confirm: #00AA4E;
$line-caution: #FFD13A;
$line-error: #E34332;
$line-help: #7E8894;
$line-default: #979797;
$line-light: #DEDEDE;
$line-table-header: #979797;
$line-table-cell: #DEDEDE;

$text-dark: #2B3134;
$text-instruction: #5F6872;
$text-hint: #8B96A2;
$text-info: #2E67C7;
$text-confirm: #007938;
$text-caution: #A58B34;
$text-warn: #D9301E;
$text-help: #44474A;
$text-table-header: #2B3134;
$text-table-cell: #616A74;

$color-white: #fff;
$page-default-secondary: #f8f8f8;

$color-primary: #498DFF;
$color-secondary: #7E8894;
$color-tertiary-a: #DCDFE0;
$color-input-selected: #F2F2F2;
$color-confirm: #00AA4E;
$color-delete: #E34332;
$color-feedback: #FDFCE3;
$color-reserved: #FFD13A;
$color-border: #e3e3e3;

body {
    background-color: $color-white;
}
// .main-sidebar {
//     background-color: $page-default-secondary;
//     border-right: 1px solid #e3e3e3;
//     box-shadow: none;

//     .sidebar-menu {
//         li {
//             a {
//                 color: #2B3134;

//                 &:hover {
//                     background-color: #f2f2f2;
//                 }
//             }

//             ul.dropdown-menu {
//                 li {
//                     a {
//                         color: #2B3134;
//                     }
//                 }
//             }
//         }
//     }
// }
.theme-white .navbar {
    // background-color: #f8f8f8;
    // box-shadow: none;
    // border-bottom: 1px solid #e3e3e3;
    height: 50px;
    padding: 8px 30px;
}
.theme-black .navbar {
    height: 50px;
    // padding: 8px 30px;
    // color: #fff;
}
.main-content{
    padding-top: 50px;
}
// .breadcrumb {
//     background-color: transparent;
//     margin: 0 -30px 10px;
//     padding: 10px 30px !important;
// }
.text1{
    font-size: 120%;
}
.text2{
    font-size: 140%;
}
.text3{
    font-size: 150%;
}
.text4{
    font-size: 160%;
}
.breadcrumb {
    margin-top: 10px;
    margin-bottom: 10px;
    li{
        a{
            &:hover{
                color:#488cfe !important;
            }
        }
        &:last-child{
            a{
                color:$text-dark !important;
                text-decoration: none;
                cursor: default;
            }
        }
    }
}

.tabscroll{
    overflow-x:scroll ;
        &:hover{
        overflow: auto;
        overflow-y:hidden ;
             &::-webkit-scrollbar {
                 height: 6px;
            }
        }
        &::-webkit-scrollbar {
         height: 0px;
        }

      /* Track */
        &::-webkit-scrollbar-track {
        background: #dee2e6; 
        }
       
      /* Handle */
        &::-webkit-scrollbar-thumb {
        background: #888; 
        border-radius: 10px;
        }
      
      /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
        background: #555; 
        }
       
}
.tabscroll .nav-tabs{
    white-space: nowrap;
    display: inline-block;
    border-bottom: 1px solid #dee2e6;
}
//.nav-tabs {
   
    // &:hover{
    //     overflow: auto;
    // overflow-y:hidden ;
    //     &::-webkit-scrollbar {
    //         height: 4px;
    //         }
    // }
    // &::-webkit-scrollbar {
    //     height: 0px;
    //     }

    //   /* Track */
    //     &::-webkit-scrollbar-track {
    //     background: #dee2e6; 
    //     }
       
    //   /* Handle */
    //     &::-webkit-scrollbar-thumb {
    //     background: #888; 
    //     border-radius: 10px;
    
    //     }
      
    //   /* Handle on hover */
    //     &::-webkit-scrollbar-thumb:hover {
    //     background: #555; 
    //     }
 //}
 .tabscroll .nav-tabs > li {
    display: inline-block;  
    // &::after{
    //     content: '';
    //     position: relative;
    //     width: 150px;
    //     border-bottom: 2px solid red;
    //     z-index: 99;
    // }
   }
//    .nav-tabs .nav-item .nav-link.active{
//     background: #dee2e6;
//    }
   
.theme-black a {
    color:$text-info;
}
.btn-outline-danger {
    color: #dc3545 !important;
}
.theme-black {
    .btn {
        box-shadow: none !important;
    }
    .btn-primary {
        background-color: $color-primary;
            &:hover, &:focus {
                background-color:#76a9ff !important;
            }
    }
    .btn-danger {
        background: #e62f2f;
        border-color: #e62f2f;;
            &:hover, &:focus {
                background-color: #f94747 !important;
            }
    }
    .text-danger{
        color: #e62f2f !important;
    }
     
    .btn-success {
        background-color: $color-confirm;
        border:$color-confirm;
            &:hover, &:focus {
                background-color:#2ac572 !important;
            }
    }
 
    .btn-link {
        color: $color-primary;
        font-weight: 700;
        background-color: transparent;
        // &:hover, &:focus {
        //     color: $color-primary !important;
        //     background-color: transparent !important;
        // }
    }
 
    .btn-secondary {
        background-color: $color-secondary;
        font-weight: 700;
        border-color: $color-secondary;
            &:hover, &:focus {
                background-color: #a4abb5 !important;
            }
    }
 
    .btn-default {
        background-color: $color-tertiary-a;
        font-weight: 700;
        color: #272822;
            &:hover, &:focus {
                background-color: #e8e8e8 !important;
                
            }
    }
    // a.btn.disabled, fieldset:disabled a.btn {
    //     pointer-events: none;
    // }
    
    // .btn-primary.disabled, .btn-primary:disabled {
    //     color: #fff;
    //     background-color: #007bff;
    //     border-color: #007bff;
    // }
    .text-purple, .text-purple *, .text-purple *:before, .text-purple *:after{
        color:#727fe2 !important
    }
    .text-navy, .text-navy *, .text-navy *:before, .text-navy *:after{
        color: #3c82af !important
    }
}

.theme-black .form-control:focus {
    border-color: $color-primary;
    background-color: $color-input-selected;
}

.theme-black .page-item .page-link {
    color: $text-instruction;
}
.theme-black .page-item.active .page-link {
    background-color: $color-primary;
    border-color: $color-primary;
    z-index: 0;
}
.theme-black .page-item.disabled .page-link {
    color: $text-instruction;
}

.page-link:hover{
    z-index: inherit !important;
    cursor: pointer;
}

.card-silver{
    border-top: 2px solid #b5b5b5;
}
.card.card-silver.shadow-sm{
    height: calc(100% - 30px);
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.1) !important;
}
.card.shadow-sm:hover {
    background: #f9f9f9;
}
.main-sidebar .sidebar-brand a .header-logo{width:50%; height: auto;}
.form-control{
    border-radius: 3px;
}
.input-group-text, 
select.form-control:not([size]):not([multiple]), 
.form-control:not(.form-control-sm):not(.form-control-lg) {
    font-size: 12px;
    padding: 6px 6px;
    height: 38px;
}
.form-control-sm{
    height: 30px !important;
}
.form-control,
.input-group-text,
.custom-select,
.custom-file-label {
    // background-color: $color-white;
    border-color: $hr-on-light;
}

// .form-group .control-label,
// .form-group > label {
//     font-weight: 700;
//     color: $text-instruction;
// }
.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: $color-delete;
}
.invalid-feedback{
    color: $color-delete;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::before {
    background-color: $color-primary !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
    border-color: $color-primary;
}
.hint-text {
    color: $text-hint;
    font-size: 12px;
}

.page-filter {
    width: 300px;
    position: fixed;
    right: 0;
    top: 50px;
    background-color: $color-white;
    // border-left: 1px solid $color-border;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    padding: 15px;
    // overflow-y: scroll;
    height: 100%;
    padding-right: 5px;
    z-index: 9;
}

#filters{
    overflow-y: scroll;
    height: 100%;
    padding-right:10px;
    box-sizing: border-box;
    padding-bottom: 95px;
    /* width */
    &::-webkit-scrollbar {
    width: 8px;
    }
  
  /* Track */
    &::-webkit-scrollbar-track {
    background: #f1f1f1; 
    }
   
  /* Handle */
    &::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
    }
  
  /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
    background: #555; 
    }
}


.closebtn{
    cursor: pointer
}
table.dataTable{
    border-collapse: collapse !important;
    margin-top: 0 !important;
    white-space: nowrap;
}

//Sticky Table 

.sticky-table-table .sorting,
.sticky-table-table .sorting_asc,
.sticky-table-table .sorting_desc,
.sticky-table-table .sorting_asc_disabled,
.sticky-table-table .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}
.sticky-table-table .sorting:before, .sticky-table-table .sorting:after,
.sticky-table-table .sorting_asc:before,
.sticky-table-table .sorting_asc:after,
.sticky-table-table .sorting_desc:before,
.sticky-table-table .sorting_desc:after,
.sticky-table-table .sorting_asc_disabled:before,
.sticky-table-table .sorting_asc_disabled:after,
.sticky-table-table .sorting_desc_disabled:before,
.sticky-table-table .sorting_desc_disabled:after {
//   position: absolute;
//   bottom: 0.9em;
//   display: block;
//   opacity: 0.5;
position: absolute;
bottom: 1em;
opacity: 0.5;
font-size: 20px;
left: 0px;
// float: left;
// margin: 0px 0px 0px 0px;
// height: 1px;
height: 9px;
width: 9px;

}
.sticky-table-table .sorting:before,
.sticky-table-table .sorting_asc:before,
.sticky-table-table .sorting_desc:before,
.sticky-table-table .sorting_asc_disabled:before,
.sticky-table-table .sorting_desc_disabled:before {
//   right: 1em;
  content: " ";
  background: url(theme/img/up-arrow.svg);
  
   
}
.sticky-table-table .sorting:after,
.sticky-table-table .sorting_asc:after,
.sticky-table-table .sorting_desc:after,
.sticky-table-table .sorting_asc_disabled:after,
.sticky-table-table .sorting_desc_disabled:after {
//   right: 0.5em;
content: " ";
background: url(theme/img/down-arrow.svg);
top: 19px;

// margin-right: 2px;
// margin-top: 26px;
// margin-left: -14px;

}
.sticky-table-table .sorting_asc:before,
.sticky-table-table .sorting_desc:after {
  opacity: 1;
}
.sticky-table-table .sorting_asc_disabled:before,
.sticky-table-table .sorting_desc_disabled:after {
  opacity: 0;
}


.sticky-table-header .sticky-table-cell {
    background:#ececec  !important;
    min-width: 150px;
    font-weight: bold;
    &:first-child{
        min-width: auto;
    }
}
.sticky-table-wrapper{
    width: 100%;
}
.sticky-table{
    height: 100%;
    max-height: calc(100vh - 250px); 
    border-radius: 10px;

    &::-webkit-scrollbar {
    width: 8px;
    height: 10px;
    }
  
  /* Track */
    &::-webkit-scrollbar-track {
    background: #f1f1f1; 
    }
   
  /* Handle */
    &::-webkit-scrollbar-thumb {
    background: rgb(170, 170, 170); 
    border-radius: 10px;

    }
  
  /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
    background: #555; 
    }
    .sticky-table-table{
        width: 100%;
        .sticky-table-row{
           
            &:nth-child(odd){
                .sticky-table-cell{
                        background:#fafafa ; 
                    }
                }
                &:hover{
                    .sticky-table-cell{
                            background: #f3f3f3; 
                        }
                    }
        }
    }
}


.table {
    th, td {
        vertical-align: middle;
        padding: 4px 5px;
        &:first-child{
            padding-left: 10px !important;
        }
    }

    thead {
        th {
            background-color: $color-input-selected !important;
            padding-top: 10px !important;
            padding-bottom: 10px !important;
            border-bottom: 1px solid #e3e3e3 !important;
            &:first-child{
                border-radius: 10px 0 0 0;
            }
            &:last-child{
                border-radius: 0 10px 0 0;
            }

            &:hover {
                background-color: $color-border !important;
            }
        }
    }
}

.flex-fill{
    width: 100%;
    position: relative;
}

.badge-c{
    background-color: #e8edf1;
    color: #191d21;
    white-space: normal;
    line-height: 16px;
}

.zui-scroller {
    margin-left: 0px;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 90%;
    min-height: 400px;

    .fixedColumnL{
        th, td {
            white-space: nowrap;
            padding:10px 7px;
            // border-top: 1px solid #dee2e6;
            &:last-child{
                // box-shadow: -3px 2px 4px rgba(0, 0, 0, 0.20);
                 //border: 1px solid #e3e3e3;
                // padding: 11px !important;
                border-left: solid 1px #dcdcdc;
                border-right: solid 1px #dcdcdc;
                right: 0px;
                position: absolute;
                top: auto;
                width: 8.5%;
                background: inherit;
            }
        }
        th{
            &:last-child{
                padding: 12px 7px !important;
            }
        }
    }
}


.sortlength{
    width:250px;
    text-overflow:ellipsis;
    // float: left;
    overflow: hidden;
    display: inline-block;
}
// .fixedColumnL td:last-child, .fixedColumnL  th:last-child {
//     border-left: solid 1px #DDEFEF;
//             border-right: solid 1px #DDEFEF;
//             right: 0;
//             position: absolute;
//             top: auto;
//             width: 15vw;
// }

table.dataTable.table-sm .sorting:after, 
table.dataTable.table-sm .sorting_asc:after, 
table.dataTable.table-sm .sorting_desc:after {
    top: 12px;
}
table.dataTable.table-sm .sorting:before, 
table.dataTable.table-sm .sorting_asc:before, 
table.dataTable.table-sm .sorting_desc:before {
    top: 12px;
}

.auto-suggest {
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    // padding: .5rem 0;
    margin: .125rem 0 0;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .25rem;
    max-height: 200px;
    overflow-y: auto;
    border:1px solid #fff;
    

    li {
        display: block;
        width: 100%;
        padding: .25rem 1rem;
        clear: both;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        // white-space: nowrap;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        background: #f7f7f7;
        margin-bottom: 1px;

        &:hover, &:focus {
            color: #16181b;
            text-decoration: none;
            background-color: #fff;
        }

        &:active {
            color: #fff;
            text-decoration: none;
            background-color: #007bff;
        }
    }
}


//calender
.react-datepicker-popper{
    left: -30px !important;
    margin-top:0px !important;
    .react-datepicker__triangle{
        margin-left: 45px!important;
        display: none;
    }
}

//table icon size
table.dataTable .far, table.dataTable .fas {
    font-size: 15px;
}

//Bottom Fixed model
.bottomfixed{
    position: relative;
    .datatop{
        margin-bottom: 205px;
        .checkbox-list{
            .bg-light-c{
                background-color: #f5f5f5;
            }
            .row{
                &:first-child{
                    .bg-light-c{
                        margin-top: 2px !important;
                    }
                }
            }
        }
       .selectAll-box {
            border-bottom: 1px solid #f1f1f1;
            padding-bottom: 10px;
        }
    }

    .databottom{
        position:absolute;
        bottom: 0;
        background-color: #fff;
        min-height: 205px;
        border-top: 1px solid #e7e7e7;
        .form-row>.col, .form-row>[class*=col-] {
            padding-right: 10px;
            padding-left: 10px;
        }
        .form-group{
            margin-bottom: 0;
        }   
    }
    .checkbox-list{
        max-height: 350px;
        overflow-y: scroll;
         overflow-x: hidden;
         padding: 15px;
             /* width */
            &::-webkit-scrollbar {
            width: 8px;
            }
        
             /* Track */
            &::-webkit-scrollbar-track {
            background: #f1f1f1; 
            }
        
          /* Handle */
            &::-webkit-scrollbar-thumb {
            background: #888; 
            border-radius: 10px;
            }
        
             /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
            background: #555; 
            }
    }
}

.card .card-body p{
    margin-bottom: .4rem;
}

.compLoader:empty {
    position: absolute;
    left: 55%;
    top: 60%;
    z-index: 1;
    margin: -75px 0 0 -75px;
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    // border-top: 8px solid #3498db;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    border-top: 8px solid #cf2336;
    border-right: 8px solid #6e4287;
    border-bottom: 8px solid #135293;
    border-left: 8px solid #50af46;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  /* custom Scrollbar start here */
  .customScrollBar{
      height: 150px;
      overflow-y: scroll;

    //   &::-webkit-scrollbar {
    //     width: 5px;
    //   }
      
    //   /* Track */
    //   &::-webkit-scrollbar-track {
    //     background: #f1f1f1; 
    //   }
       
    //   /* Handle */
    //   &::-webkit-scrollbar-thumb {
    //     background: #888; 
    //   }
      
    //   /* Handle on hover */
    //   &::-webkit-scrollbar-thumb:hover {
    //     background: #555; 
    //   }

  }
  /* custom Scrollbar end here */
  .react-datepicker-wrapper{
      display: block !important;
  }
  .accoridan-ss{
    box-shadow: none;
      background: #f9f9f9;
      border-radius: 0;
      margin-bottom: 10px;
      padding: 0;
  }

  .accoridan-ss--header{
    padding:5px !important; 
  }
  .main-sidebar .sidebar-menu li a{
      line-height:13px;
  }

  .without_ampm::-webkit-datetime-edit-ampm-field {
    display: none;
  }
  input[type=time]::-webkit-clear-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance:none;
    appearance: none;
    margin: -10px; 
  }