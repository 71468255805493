.tab-content {
  &.no-padding {
    >.tab-pane {
      padding: 0;
    }
  }

  >.tab-pane {
    line-height: 28px;
  }
}